.chromabg {
    background-color: #00FF00;
    width: 100vw;
    height: 100vh;
}

.overlay-text {
    width: 300px;
    text-align: center;
    color: #926f17;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}
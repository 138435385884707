.timestats {
    dl {

        dt {
            font-weight: bold;
            &:after {
                content: ':';
            }
        }
        dd {
            margin-bottom: 0.7em;
        }
    }
}
$track-color: #483730;
$start-gradient: #c57741;
$end-gradient: #e7bf83;
$radius: 11px;

.progress-bar-track {
    background-color: $track-color;
    border-radius: $radius;
    height: 13px;
    padding: 3px;
}

.progress-bar {
    height: 100%;
    border-radius: $radius 0 0 $radius;
    background-image: linear-gradient(to right, $start-gradient , $end-gradient);
    transition: 1s ease;
    transition-delay: 0.5s;
}
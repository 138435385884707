$bg-color: #e1d4c1;

.card {
    border: 1px solid #543f2a;
    background-color: $bg-color;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: inset 0 0 10px #bf9e74, 0px 5px 6px -5px #442400;

    &-label {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 15px;
    }
}
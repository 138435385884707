$text-color: #926f17;
$bg-color: #e1d4c1;
@import-normalize;

body {
    background-color: $bg-color;
    background-image: url('images/natural-paper.webp');
    font-family: 'Roboto', 'Segoe UI', sans-serif;
    color: $text-color;
    display: flex;
    height: 100vh;
    flex-direction: column;
    margin: 0;
}

.App {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: hidden;
    margin:0;
}
.content { 
    overflow-y: auto;
    padding-bottom: 0px
}

.container {
    margin: 0px 10px;
    @media (min-width: 600px) {
        width: 580px;
        margin: 0px auto;
    }
    @media (min-width: 900px) {
        width: 860px;
        margin: 0 auto;
    }
    @media (min-width: 1200px) {
        width: 1140px;
        margin: 0px auto;
    }
}

header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 900px) {
        flex-direction: column;
    }
}

.header-icons {
    img {
        width: 32px;
        height: 32px;
    }
    .discord {
        img {
            cursor: pointer;
        }
        span {
            display: inline-block;
            margin-left: 5px;
            padding-top: 7px;
            font-weight: bold;
            font-size: 18px;
            overflow: hidden;
            height: 32px;
            vertical-align: top;
            width: 0;
            transition: width 250ms ease-in;
            &.expand {
                width: 134px;
            }
        }
        
    }
    display: flex;
    flex-direction: row;
}

h1 {
    font-family: 'Cinzel', 'Times New Roman', Times, serif;
    font-size: 2em;
    white-space: nowrap;
    @media (max-width: 420px) {
        font-size: 1.5em;
    }
}

.foot-note {
    padding-bottom: 400px;
}

.footer {
   width: 100%;
   display: flex; 
   flex-direction: row;
   flex-shrink: 0;
   position: fixed;
   bottom: 0;
}

.footer > div {
    width: 50%;
}

.footer-right, .footer-left {
    position: relative;
}

.footer-logo {
    position: absolute;
    bottom: 0px;
    right: 15px;
}

@keyframes rezlg {
    from { background-position-x: 0px; }
    to { background-position-x: -900px;}
}

@keyframes rezmd {
    from { background-position-x: 0px; }
    to { background-position-x: -405px;}
}

@keyframes rezsm {
    from { background-position-x: 0px; }
    to { background-position-x: -213px;}
}

.rez {
    width: 300px;
    height: 890px;
    position: absolute;
    left: 20px;
    bottom: 0px;
    background-image: url('images/reznix.webp');
    background-position-x: 0;
    animation: rezlg 15s;
    animation-timing-function: steps(3);
    animation-iteration-count: infinite;
}

.rez-md {
    width: 135px;
    height: 400px;
    position: absolute;
    left: 20px;
    bottom: 0px;
    background-image: url('images/reznix-md.webp');
    background-position-x: 0;
    animation: rezmd 15s;
    animation-timing-function: steps(3);
    animation-iteration-count: infinite;
}

.rez-sm {
    width: 65px;
    height: 200px;
    position: absolute;
    left: 20px;
    bottom: 0px;
    background-image: url('images/reznix-sm.webp');
    background-position-x: 0;
    animation: rezsm 15s;
    animation-timing-function: steps(3);
    animation-iteration-count: infinite;
}


/* Small only */
@media screen and (max-width: 39.9375em) {
    .rez-sm {
        display: block;
    }

    .rez-md {
        display: none;
    }

    .rez {
        display: none;
    }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .rez-sm {
        display: none;
    }

    .rez-md {
        display: block;
    }

    .rez {
        display: none;
    }
}

/* large and up */
@media screen and (min-width: 64em) {
    .rez-sm {
        display: none;
    }

    .rez-md {
        display: none;
    }

    .rez {
        display: block;
    }
}

$pip-size: 15px;
.custom-legend {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    .pip {
        border-radius: $pip-size;
        display: inline-block;
        width: $pip-size;
        height: $pip-size;
        margin-right: 5px;
    }
    .legend-item {
        padding-right: 15px;
        position: relative;
        span {
            display: inline-block;
            margin-bottom: -2px;
        }
    }
}